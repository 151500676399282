.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background: rgba(42, 42, 42, .45);
  backdrop-filter: saturate(115%) blur(30px);
  -webkit-backdrop-filter: saturate(115%) blur(30px);
  color: white;
}

.overlay.active {
  display: flex;
}

.modal {
  width: 420px;
  padding: 24px;
  max-height: calc(100vh - 128px);
  border-radius: 12px;
  overflow: hidden;
}

.modal button, .modal a:global(.btn) {
  background: rgba(42, 42, 42, .15);
  backdrop-filter: saturate(115%) blur(30px);
  -webkit-backdrop-filter: saturate(115%) blur(30px);
}

.modal button:hover, .modal a:global(.btn):hover {
  background: rgba(61, 61, 61, .15);
}

.modal input {
  background: rgba(255, 255, 255, .15);
  border: none;
  display: block;
  width: calc(100% - 16px);
  height: 28px;
  border-radius: 4px;
  margin: 16px 0px;
  font-family: inherit;
  font-size: 18px;
  padding: 8px;
  outline: none;
  color: inherit;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.modal input:global(.has-error) {
  background-color: rgb(255, 69, 58, .25);
}

.modal :global(.input-error) {
  color: #FF453A;
  font-size: 12px;
}
