.listing {
  display: flex;
  width: 768px;
  position: relative;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 768px) {
  .listing {
    width: 100vw;
    height: 100vh;
  }
}

.listing .section.texts {
  flex-grow: 1;
  padding-right: 32px;
  margin-top: 128px;
}

@media only screen and (max-width: 768px) {
  .listing .section.texts {
    display: flex;
    flex-direction: column;
    padding: 8px;
    padding-left: 6px;
    margin: -1px;
    z-index: 1;
    padding-top: 24px;
    background: linear-gradient(180deg, rgba(0, 0, 0, .65), transparent 40%, transparent, rgba(0, 0, 0, .85) 95%);
    padding-bottom: env(safe-area-inset-bottom)
  }

  .listing .section.texts h2 {
    max-width: calc(100% - 56px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 768px) {
  .listing .section.video {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}