.row {
  display: flex;
}

.row input {
  margin: 16px 8px;
  font-size: 24px;
  height: 44px;
  text-align: center;
}

.row input:first-child {
  margin-left: 0;
}

.row input:last-child {
  margin-right: 0;
}
