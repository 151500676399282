.header {
  position: fixed;
  right: calc(65% + 32px);
  width: 378px;
  top: 0; 
}

.container {
  padding-left: 35%;
  width: 100%;
}

.listings {
  width: 774px;
}

.logo {
  margin-top: 128px;
  width: 256px;
}

.title {
  font-weight: normal;
  font-size: 24px;
  padding-left: 36px;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0;
  }

  .listings {
    width: 100vw;
  }

  .header {
    position: fixed;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 35%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
    pointer-events: none;
  }

  .header h1 {
    font-size: 20px;
  }

  .logo {
    margin-top: 8px;
    width: 192px;
  }

  .footer {
    pointer-events: all;
    position: fixed;
    padding: 8px;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 156px;
    display: flex;
    flex-direction: column-reverse;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .85));
  }
}

@media screen and (max-width: 1190px) and (min-width: 769px) {
  .container {
    padding-left: 40vw;
  }

  .header {
    right: auto;
    left: 8px;
    width: calc(40vw - 16px);
  }

  .listings {
    width: 60vw;
  }
}

@media screen and (min-width: 1570px) {
  .listings {
    width: 1032px;
  }
}
