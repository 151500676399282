.cell {
  display: inline-block;
  width: 256px;
  height: 368px;
  position: relative;
  border-radius: 2px;
  background: linear-gradient(to top, #111111, #212121);
  margin: 1px;
  overflow: hidden;
}

.cell video {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.overlay {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  left: 0px;
  bottom: 0px;
  right: 0px;
  top: 0px;
  padding: 8px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  color: white;
}

.overlay h1 {
  font-size: 32px;
}

.overlay h2 {
  font-size: 18px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


@media screen and (max-width: 768px) {
  .cell {
    width: calc(50vw - 2px);
    height: calc(50vw * 1.44 - 2px);
  }

  .overlay h1 {
    font-size: 24px;
  }

  .overlay h2 {
    font-size: 16px;
  }
}

@media screen and (max-width: 1190px) and (min-width: 769px) {
  .cell {
    width: calc(20vw - 2px);
    height: calc(20vw * 1.44 - 2px);
  }
}