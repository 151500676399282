.modal a span,
.modal button span {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin: 16px;
}

.modal a span i,
.modal button span i {
  flex-grow: 1;
  text-align: right;
  margin: 0;
}

.modal button span i {
  margin-right: -2px;
}