.footer small {
  display: block;
  text-align: justify;
}

.footer a:not(:global(.btn)), .footer b {
  color: inherit;
  text-decoration: none;
  font-size: 12px;
  margin: 0 16px;
}

.footer b {
  cursor: pointer;
  font-weight: normal;
}

.spacer {
  height: 24px;
}