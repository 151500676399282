.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;
}

img.background {
  opacity: .1;
  height: 75vmax;
  margin-top: -10vmax;
}

@media (prefers-color-scheme: light) {
  img.background {
    opacity: .2;
  }
}

.container {
  margin: 0 auto;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

@media screen and (max-width: 768px) {
  .container {
    overflow: hidden;
  }
}