body {
  margin: 0;
  background: black;
  font-family: 'graphis', 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  margin: 0;
  font-size: 36px;
}

h2 {
  margin: 0;
  font-size: 30px;
}

p {
  margin: 0;
  font-size: 18px;
}

hr {
  border: 1px solid rgba(128, 128, 128, .5);
  border-top: none;
  margin: 24px;
}

a.link {
  text-decoration: none;
  color: #0084FF;
}

button, a.btn {
  font-family: inherit;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 0;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  background: rgba(0, 0, 0, .85);
  color: white;
  height: 44px;
  border: 1px solid rgba(255, 255, 255, .2);
  backdrop-filter: saturate(115%) blur(30px);
  -webkit-backdrop-filter: saturate(115%) blur(30px);
  transition: background .15s;
}

button:hover, a.btn:hover {
  background: rgba(21, 21, 21, .85);
}

button i, a.btn i {
  font-style: normal;
  font-size: 24px;
  font-weight: 100;
  display: inline-block;
  vertical-align: -3px;
  margin-right: 8px;
}

.btn-row {
  display: flex;
  gap: 8px;
}

img.icon {
  display: inline-flex;
  justify-content: center;
  width: 32px;
  vertical-align: -10px;
}

.spacer {
  flex-grow: 1;
}

@media (prefers-color-scheme: dark) {
  body {
    background: #000;
    color: #fff;
  }

  button, a.btn {
    background: rgba(42, 42, 42, .45);
  }

  button:hover, a.btn:hover {
    background: rgba(61, 61, 61, .45);
  }
}

@media screen and (max-width: 768px) {
  body {
    background: #000;
    color: #fff;
  }

  button, a.btn {
    height: 56px;
    border-radius: 10px;
    background: rgba(42, 42, 42, .45);
  }

  button:hover, a.btn:hover {
    background: rgba(61, 61, 61, .45);
  }
}

[justmobile] {
  display: none;
}


@media screen and (max-width: 768px) {
  [justdesktop] {
    display: none !important;
  }

  [justmobile] {
    display: block !important;
  }
}

body.android [noandroid],
body.iphone [noiphone],
body:not(.android) [justandroid],
body:not(.iphone) [justiphone] {
  display: none !important;
}
